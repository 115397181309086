import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Segmentos from '../pages/Segmentos';
import LP from '../pages/LP';
import QuemSomos from '../pages/QuemSomos';
import TrabalheConosco from '../pages/TrabalheConosco';
import Blog from '../pages/Blog';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={ Home } />
            <Route exact path='/Segmentos' component={ Segmentos } />
            <Route exact path='/LP' component={ LP }  />
            <Route exact path='/QuemSomos' component={ QuemSomos }  />
            <Route exact path='/TrabalheConosco' component={ TrabalheConosco }  />
            <Route exact path='/Blog' component={ Blog }  />
        </Switch>
    </BrowserRouter>
);

export default Routes;